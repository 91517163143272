<template>
  <div class="file-name">
    <div class="file-title">
      <uds-icon
        v-if="dropzoneComposable.isValid"
        icon-name="done"
        size="small"
        :color="primaryColor.primary100"
        :style="`background-color: ${udsColors.udsSuccess500}; border-radius: 9999px`"
      />
      <uds-tooltip
        v-if="!dropzoneComposable.isValid"
        :tooltip-text="dropzoneComposable.errors().join(', ')"
      >
        <uds-icon icon-name="info" size="small" :color="udsColors.udsError500" />
      </uds-tooltip>

      <div class="truncate">{{ file.name }}</div>
    </div>

    <uds-icon
      icon-name="delete"
      size="small"
      :color="udsColors.udsNeutral800"
      @click.stop="$emit('delete', index)"
    />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  file: File;
  index: number;
  rules: DropzoneRules;
}>();

const primaryColor = computed(() => getPrimaryColors());
const udsColors = getUdsColors();

defineEmits<{ (e: "delete", index: number): void }>();
const dropzoneComposable = computed(() =>
  useDropzoneComposable(props.file, props.index, props.rules),
);
</script>

<style lang="scss" scoped>
.file-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $uds-spacing-2;
  text-align: start;
  max-width: 80%;
}

.file-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $uds-primary-50;
  color: $uds-neutral-900;
  padding: $uds-spacing-1;
  border-radius: $uds-radius-1;
  font-family: $default-font-family;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>

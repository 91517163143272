export type DropzoneRules = {
  maxFiles: number | null;
  maxSize: number | null;
  extensions: FileMimeType[];
};

export function useDropzoneComposable(file: File, index: number, rules: DropzoneRules) {
  const size = file.size / 1000000;

  const errors = (): string[] => {
    const { maxFiles, maxSize, extensions } = rules;

    const e: string[] = [];
    if (isDefined(maxFiles)) {
      if (index + 1 > maxFiles) {
        e.push(
          `Vous ne pouvez pas importer plus que ${maxFiles} fichier${maxFiles > 1 ? "s" : ""}`,
        );
      }
    }

    if (isDefined(maxSize)) {
      if (size > maxSize) {
        e.push(`Le fichier dépasse la limite autorisée (${maxSize}Mo)`);
      }
    }

    if (isDefined(extensions)) {
      if (!extensions.includes(file.type as FileMimeType)) {
        e.push(`L'extension du fichier est invalide`);
      }
    }

    return e;
  };

  const isValid = errors().length === 0;

  return { size, errors, isValid };
}

<template>
  <span>
    <div
      id="dropzone-container"
      ref="dropZoneRef"
      class="scrollbar"
      :class="{ active: isOverDropZone, error: errorMessage, disabled }"
      style="position: relative"
    >
      <div
        class="files-container"
        @click="disabled ? null : open({ accept: extensions.join(), multiple: maxFiles > 1 })"
      >
        <template v-if="!files.length">
          <uds-icon class="cloud_icon" icon-name="cloud_upload" size="xlarge" />
          <template v-if="!customText">
            <div class="uds-headers --h4">
              <div v-show="!isOverDropZone">
                Importer {{ maxFiles > 1 ? "des" : "un" }} {{ fileWord
                }}{{ maxFiles > 1 ? "s" : "" }}
              </div>
              <div v-show="isOverDropZone" class="uds-headers --h4">Ajouter les documents</div>
            </div>
            <div class="subtitle">
              <p class="uds-paragraph">Formats autorisés : {{ extensionsName }}</p>
              <p class="uds-paragraph">Poids maximal par fichier : {{ maxSize }}Mo</p>
            </div>
          </template>
          <slot name="content" />
        </template>

        <div v-else style="display: flex; place-content: center; width: fit-content">
          <div class="files-list scrollbar">
            <CommonsDropzoneFile
              v-for="(file, index) of files"
              :key="index"
              :file="file"
              :index="index"
              :rules="rules"
              @delete="reset($event)"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- DROPBOX FILE PICKER -->
    <div v-if="showDropBoxButton" style="margin-top: 12px">
      <div class="uds-paragraph --small">
        <button
          :disabled="disabled"
          :class="`dropbox-button ${disabled ? 'disabled' : ''}`"
          @click="handleClick"
        >
          <SvgDropboxLogoSvg class="logo" />
          Ajouter depuis Dropbox
        </button>
      </div>
    </div>
    <div v-if="errorMessage" class="uds-paragraph --small error-message">{{ errorMessage }}</div>
  </span>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useDropZone, useFileDialog } from "@vueuse/core";
import type { FileMimeType } from "~/utils/file-type";

const props = withDefaults(
  defineProps<{
    extensions: FileMimeType[];
    maxFiles: number;
    maxSize: number; // in Mo
    error?: string | null;
    disabled?: boolean;
    directError?: boolean;
    customText?: boolean;
    showDropBoxButton?: boolean;
    fileWord?: string;
  }>(),
  {
    error: null,
    showDropBoxButton: false,
    fileWord: "fichier",
  },
);

type DBoxDoc = {
  bytes: number;
  icon: string;
  id: string;
  isDir: boolean;
  link: string;
  linkType: string;
  name: string;
};

const hasChanged = ref(false);

const emit = defineEmits<{
  (e: "updated", value: Array<File>): void;
}>();

const { open, files: filesFromDialog } = useFileDialog({
  multiple: props.maxFiles > 1,
  accept: props.extensions.join(),
  directory: false,
});

function reset(index: number) {
  files.value.splice(index, 1);
  updateEvent();
}

const files = ref<File[]>([]);

watch(filesFromDialog, (value) => {
  if (value) {
    files.value = [...files.value, ...value];
    updateEvent();
  }
});

const extensionsName = computed<string>(() =>
  props.extensions.map((e) => (e === "text/plain" ? ".txt" : `.${e.split("/")[1]}`)).join(", "),
);

const errorMessage = computed<string | null>(() => {
  return hasChanged.value || props.directError ? (props.error ?? null) : null;
});

const rules = { maxFiles: props.maxFiles, extensions: props.extensions, maxSize: props.maxSize };

function updateEvent() {
  hasChanged.value = true;
  emit("updated", files.value);
}

const dropZoneRef = ref<HTMLDivElement>();
const { isOverDropZone } = useDropZone(dropZoneRef, {
  onDrop: (dzFiles: File[] | null) => {
    if (!dzFiles) return;

    files.value = [...files.value, ...dzFiles];
    updateEvent();
  },
  dataTypes: props.extensions,
});

/**
 * Dropzone script
 */
const dropboxScript = document.createElement("script");
dropboxScript.src = "https://www.dropbox.com/static/api/2/dropins.js";
dropboxScript.id = "dropboxjs";

dropboxScript.setAttribute("data-app-key", "93fscyxir4rxstf");
document.head.appendChild(dropboxScript);

const dropBoxFiles = ref<File[]>([]);

function handleClick(): void {
  // Remove all dropbox files in files ref
  files.value = files.value.filter((file) => !dropBoxFiles.value.includes(file));
  dropBoxFiles.value = [];

  const options = {
    success: (documents: DBoxDoc[]) => {
      documents.forEach((doc: DBoxDoc) => {
        downloadDropBoxFile(doc);
      });
    },
    linkType: "direct",
    multiselect: props.maxFiles > 1,
    extensions: props.extensions.map((e) => `.${e.split("/")[1]}`),
  };
  // @ts-expect-error
  Dropbox.choose(options);
}

async function downloadDropBoxFile(document: DBoxDoc) {
  try {
    const blob = await $fetch<Blob>(`${document.link}?dl=1`, {
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${"93fscyxir4rxstf"}`,
      },
    });

    const file = new File([blob], document.name, { type: blob.type });
    dropBoxFiles.value.push(file);

    files.value = [...files.value, ...dropBoxFiles.value];
    updateEvent();
  } catch {
    $notifier().open({
      type: "error",
      content: "Erreur lors du téléchargement du fichier depuis DropBox",
    });
  }
}
</script>

<style lang="scss" scoped>
#dropzone-container {
  // min-height: 200px;
  height: 158px;
  // max-height: 158px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  overflow: auto !important;
  border: 1px dashed $uds-neutral-600 !important;
  background: #f1f2f4;
  border-radius: 5px;
  flex-direction: column;
  .cloud_icon {
    margin-bottom: 10px;
    color: $uds-neutral-700;
  }
  .subtitle {
    margin-top: 5px;
    p {
      text-align: center;
      color: $uds-neutral-800;
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }

  &:hover:not(.disabled),
  &.active:not(.disabled) {
    cursor: pointer;
    background-color: $uds-primary-50;
    transition: 0.3s;
    .cloud_icon {
      color: $uds-primary-300;
    }
  }

  &.error {
    border: 1px solid $uds-error-300 !important;
  }

  &.disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  .files-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
  }

  .files-list {
    max-height: 128px;
    display: flex;
    flex-direction: column;
    gap: $uds-spacing-1;
    width: 288px;
  }
}

// DROPBOX BUTTON
.dropbox-button {
  font-family: $default-font-family;
  font-weight: 100;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  border: 1px solid #dbdbdb;
  border-radius: 0.375rem;
  transition: box-shadow 0.2s;
  margin-bottom: $uds-spacing-2;
  cursor: pointer;
}
.dropbox-button:hover {
  // box-shadow: 1px 1px 5px -1px rgba(33, 33, 33, 0.2);
  background-color: $uds-neutral-100;
}
.disabled {
  opacity: 0.6;
}
button:active {
  outline: none !important;
}
button:focus {
  outline: none !important;
}
.logo {
  width: 18px;
  height: 18px;
  margin-right: 0.75rem;
}
</style>
